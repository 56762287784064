import Layout from "../components/Layout"
import {Link} from "gatsby"
import React from "react"
import Seo from "../components/seo"
import SimplePage from "../components/SimplePage"

const FairUsePage = () => (
  <Layout>
    <Seo title="Fair Use" />
    <SimplePage>
      <div className="ele-style">
        <h1>Fair Use</h1>
        <h2>Fair Use Act Disclaimer</h2>
        <p>The majority of information contained on this website is for educational and research purposes. It is believed that copyrighted material, we do not own, may be used under the fair use provision of United States copyright law.</p>

        <h2>Fair Use Copyright Disclaimer</h2>
        <p>Under section 107 of the Copyright Act of 1976, allowance is made for "fair use" for purposes such as criticism, comment, news reporting, teaching, scholarship, education and research. Fair use is a use permitted by copyright statute that might otherwise be infringing.</p>

        <h2>Fair Use Definition</h2>
        <p>Fair use is a doctrine in United States copyright law that allows limited use of copyrighted material without requiring permission from the rights holders, such as criticism, commentary, news reporting, teaching, scholarship, or education and research. It provides for the legal, non-licensed citation or incorporation of copyrighted material in another author’s work under a four-factor balancing test.</p>

        <h2>Disclaimer &amp; Fair Use Statement</h2>
        <p>This website may contain copyrighted material, the use of which may not have been specifically authorized by the copyright owner. Some material is made available in an effort to explain subject matter relevant to the status, history, and development of specific guitars, companies and players, and/or to illustrate, transmit, and teach the use and benefits of musical gear. Some material contained in this website is distributed for research and educational purposes, to prove factual accuracy, and for the public good.</p>

        <h2>Reporting Claims</h2>
        <p>We strive to provide credit for all materiel we do not own, and we take claims of copyright infringement seriously. If your copyrighted material appears on this website and you disagree with our assessment that it constitutes "fair use," please <Link to="/contact">contact us</Link>.</p>
      </div>
    </SimplePage>
  </Layout>
)

export default FairUsePage
